import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import Icon from "../../assets/images/bbell.png";
import ContentLoader from "../common/ContentLoader";
import { getDashboadStats } from "../../services/DashboardServices";
import { BsCurrencyDollar } from "react-icons/bs"

function Dashboard() {
  const [stats, setStats] = useState({});
  useEffect(() => {
    const fetch = async () => {
      try {
        const _stats = await getDashboadStats();
        setStats(_stats.data);
        console.log("_stats data======>", _stats.data);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetch();
  }, []);

  return (
    <section className="section-head">
      <Header />
      <div className="content-body">
        <div className="main-section-dashboard">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="heading">Dashboard</h1>
            <div className="imgContainer">
              <img src={Icon} alt="bell" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-6 my-3">
              <div className="main-outer-box">
                <div className="text-box">
                  <h4 className="text-center py-3">Total Subscribers</h4>
                </div>
                <div className="ring blue">
                  {stats?.totalSubs !== undefined ? (
                    <h2>{stats?.totalSubs}</h2>
                  ) : (
                    <ContentLoader />
                  )}
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 my-3">
              <div className="main-outer-box">
                <div className="text-box">
                  <h4 className="text-center py-3">Total Free Users</h4>
                </div>
                <div className="ring purple">
                  {stats?.freeSubs !== undefined ? (
                    <h2>{stats?.freeSubs}</h2>
                  ) : (
                    <ContentLoader />
                  )}
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 my-3">
              <div className="main-outer-box">
                <div className="text-box">
                  <h4 className="text-center py-3">New Subscribers</h4>
                </div>
                <div className="ring orange">
                  {stats?.newSubs !== undefined ? (
                    <h2>{stats?.newSubs}</h2>
                  ) : (
                    <ContentLoader />
                  )}
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 my-3">
              <div className="main-outer-box">
                <div className="text-box">
                  <h4 className="text-center py-3">
                    Subscription Ended Subscribers
                  </h4>
                </div>
                <div className="ring red">
                  {stats?.endSubs !== undefined ? (
                    <h2>{stats?.endSubs}</h2>
                  ) : (
                    <ContentLoader />
                  )}
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 my-3">
              <div className="main-outer-box">
                <div className="text-box">
                  <h4 className="text-center py-3">
                    Amount Received via Subscription
                  </h4>
                </div>
                <div className="ring green">
                  {stats?.amountReceived !== undefined ? (

                   <><h2>${stats?.amountReceived} </h2>
                   </>
                  ) : (
                    <ContentLoader />
                  )}
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 my-3">
              <div className="main-outer-box">
                <div className="text-box">
                  <h4 className="text-center py-3">Total Users</h4>
                </div>
                <div className="ring blue" style={{ marginTop: "46px" }}>
                  {stats?.totalUsers !== undefined ? (
                    <h2>{stats?.totalUsers}</h2>
                  ) : (
                    <ContentLoader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
