import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import Icon from "../../assets/images/bbell.png";
import AddPlan from "./PlanSections/AddPlan";
import { fetchPermissionList } from "../../services/PermissionServices";
import { HiArrowCircleLeft } from "react-icons/hi";

import PlanList from "./PlanSections/PlansList";

import EditPlan from "./PlanSections/EditPlan";

function Plansetting() {
  const [permissions, setPermissions] = useState([]);
  const [btnClicked, setBtnClicked] = useState("list");
  const [selectedPlan, setSelectedPlan] = useState({});
  useEffect(() => {
    const fetch = async () => {
      try {
        const p = await fetchPermissionList();
        setPermissions(p?.data);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetch();
  }, []);

  const handleBtnSelect = (_btn, data) => {
    setBtnClicked(_btn);
    setSelectedPlan(data);
  };

  return (
    <section className="section-head">
      <Header />
      <div className="content-body">
        <div className="main-section">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="heading">Subscription Plans</h1>
            <div className="imgContainer">
              <img src={Icon} alt="bell" />
            </div>
          </div>

          <div className="btn-box mt-3 py-4 justify-content-between">
           
              <div className="back-btn" onClick={() => handleBtnSelect("list")}>
              {btnClicked !== "list" && ( <><HiArrowCircleLeft /> <span>Back</span></>)}
              </div>
           

            <button className="plan-btn" onClick={() => setBtnClicked("add")}>
              Add Plan
            </button>
          </div>

          <div className="plan-content">
            {btnClicked === "add" && (
              <AddPlan
                permissions={permissions}
                handleBtnSelect={handleBtnSelect}
              />
            )}
            {btnClicked === "edit" && (
              <EditPlan
                id={selectedPlan?.plan_id}
                permissions={permissions}
                handleBtnSelect={handleBtnSelect}
              />
            )}
            {btnClicked === "list" && (
              <PlanList handleBtnSelect={handleBtnSelect} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Plansetting;
