import React, { Component } from "react";
import Slider from "react-slick";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import {
  ArchivePlans,
  DeletePlans,
  fetchPlans,
  getUserCountForPlans,
} from "../../../services/PlanServices";
import Currencies from "../../../config/currency.json";
import { UpdatePlanStatus } from "../../../services/PlanServices";
import { toast } from "react-hot-toast";
import { AiFillCheckCircle, AiOutlineInfoCircle } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";
import { selectLabel } from "../../../config/selectLabel";
import { AiTwotoneDelete } from "react-icons/ai";

export default class PlanList extends Component {
  state = {
    hasOpen: false,
    plans: "none",
    selectedPlan: null,
    sOptions: "",
    selectedIndex: null,
    selectedPlanIndex: null,
    userCountOnPlan: null,
    toggle: false,
    hasDelOpen: false,
    selectedPlanId: ""
  };

  onButtonClick = (p) => {
    this.setState({ ...this.state, hasOpen: true, selectedPlan: p });
  };

  onCloseModal = () => {
    this.setState({ ...this.state, hasOpen: false });
  };
  onDelCloseModal = () => {
    this.setState({ ...this.state, hasDelOpen: false });
  };

  handleChangeStatus = async () => {
    try {
      const res = await UpdatePlanStatus({
        plan_id: this.state.selectedPlan?.plan_id,
        status: !this.state.selectedPlan?.status,
      });
      if (res.statusCode === 200) {
        this.setState({ ...this.state, hasOpen: false });
        toast.success(res.message);
        setTimeout(() => {
          window.location.href = "/plan";
        }, 1000);
        return;
      } else {
        this.setState({ ...this.state, hasOpen: false });
        if (res?.message?.includes("Authentication Error")) {
          toast.error("Your session has expired. Please login again.");
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
          return;
        }
        console.log(res.message);
        return;
      }
    } catch (error) {
      this.setState({ ...this.state, hasOpen: false });
      console.log(error);
      return;
    }
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
    const fetch = async () => {
      try {
        const plans = await fetchPlans();
        if (plans.statusCode === 200) {
          this.setState({
            ...this.state,
            plans: plans.data,
          });
        } else {
          this.setState({
            ...this.state,
            plans: [],
          });
          console.log("err", plans);
        }
      } catch (error) {
        console.log("err", error);
      }
    };

    const fetchCountOnPlan = async () => {
      try {
        const _res = await getUserCountForPlans();
        if (_res.statusCode === 200) {
          this.setState({
            ...this.state,
            userCountOnPlan: _res.data,
          });
        } else {
          console.log("error", _res);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchCountOnPlan();
    fetch();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }


  handleClickOutside = (event) => {
    const box = document.querySelectorAll(".info-box");
    box.forEach((item) => item.classList.remove("show"));
  };

  handleInfo = (e, k, i, _options) => {
    e.preventDefault();

    let str = "";
    _options?.map((v) => {
      str = str + " " + (typeof v === "string" ? v : selectLabel[v]);
    });
    this.setState({
      ...this.state,
      sOptions: str,
      selectedIndex: k,
      selectedPlanIndex: i,
    });
    const box = document.querySelectorAll(".info-box");
    box.forEach((items) => items.classList.toggle("show"));
  };

  handleDeletePlan = async () => {
    try {
     
      const _del = await DeletePlans(this.state.selectedPlanId);
      if(_del){
        toast.success(_del.message);
        setTimeout(() => {
          window.location.href = "/plan";
        }, 1000);
        return;
      }else {
        if (_del?.message?.includes("Authentication Error")) {
          toast.error("Your session has expired. Please login again.");
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
          return;
        }
        console.log(_del.message);
        return;
      }
    } catch (error) {
      console.log(error);
      return;
    }
  };

  

  render() {
    const { hasOpen, hasDelOpen } = this.state;
    const { handleBtnSelect } = this.props;

    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        {this.state.plans !== "none" && this.state.plans?.length <= 0 ? (
          <div className="no-plan-text">
            <p className="plan-text-heading">No Active Plan To Show</p>
            <p className="plan-text">Please add plans from Add Plan section</p>
          </div>
        ) : (
          <>
            <h4>Plan List</h4>
            <Slider {...settings}>
              {this.state.plans !== "none" &&
                this.state.plans?.length > 0 &&
                this.state.plans?.map((p, i) => {
                  return (
                    <div className=" mt-2 after-slider">
                      <div className="card-box-slider mt-4 ">
                        <div>
                          <h2 className="card-heading-text pt-5 m-0">
                            {p?.name} {!p?.status && <span class="badge badge-secondary archived-badge">Archived</span>}
                          </h2>
                          <span className="heading-bottom-line"></span>
                        </div>

                        <div className="mt-3 p-3">
                          <ul className="card-list plan-list-ul">
                            {Object.keys(p?.functions).map((key) => {
                              return typeof p?.functions[key]._value ===
                                "number" &&
                                p?.functions[key]._value !== "0" &&
                                p?.functions[key]._value !== 0 ? (
                                <li className="pt-1 px-1 d-flex justify-content-between align-items-center">
                                  <div>
                                    <AiFillCheckCircle className="check-in-circle" />
                                    <span>{p?.functions[key]._label}</span>
                                  </div>
                                  <span className="plan-value">
                                    {p?.functions[key]?._value}
                                  </span>
                                </li>
                              ) : typeof p?.functions[key]._value ===
                                  "object" &&
                                p?.functions[key]._value?.length > 0 ? (
                                <li className="pt-1 px-1 d-flex justify-content-between align-items-center">
                                  <div>
                                    <AiFillCheckCircle className="check-in-circle" />
                                    <span>{p?.functions[key]._label}</span>
                                  </div>
                                  <div
                                    className="pointer info-icon-size"
                                    title="click to view selected values"
                                    onClick={(e) =>
                                      this.handleInfo(
                                        e,
                                        key,
                                        i,
                                        p?.functions[key]?._value
                                      )
                                    }
                                  >
                                    <AiOutlineInfoCircle />
                                    {this.state.selectedIndex === key &&
                                      this.state.selectedPlanIndex === i &&
                                      this.state.sOptions !== "" && (
                                        <span className="info-box show">
                                          {this.state.sOptions}
                                        </span>
                                      )}
                                  </div>
                                </li>
                              ) : typeof p?.functions[key]._value ===
                                  "boolean" && p?.functions[key]._value ? (
                                <li className="pt-1 px-1 d-flex justify-content-between align-items-center">
                                  <div>
                                    <AiFillCheckCircle className="check-in-circle" />
                                    <span>{p?.functions[key]._label}</span>
                                  </div>
                                </li>
                              ) : (
                                <li className="pt-1 px-1 d-flex justify-content-between align-items-center">
                                  <div>
                                    <RxCrossCircled className="check-in-circle" />
                                    <span>{p?.functions[key]._label}</span>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="card-flex">
                          {/* <div className="Price-tag">Price</div> */}
                          <div>
                            {p?.price == 0 ? (
                              <div className="free">Free</div>
                            ) : (
                              <div className="amount-box">
                                <div className="symbol ">
                                  {
                                    Currencies[p?.currency?.toUpperCase()]
                                      ?.symbol
                                  }
                                </div>
                                <div className="free">{p?.price}</div>
                                <div className="period">per {p?.period}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="card-btn-box">
                        <button
                          className="card-btn"
                          onClick={() => handleBtnSelect("edit", p)}
                        >
                          Edit Plan
                        </button>
                        <div>
                          <button
                            className="delete-icon"
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                hasDelOpen: true,
                                selectedPlanId: p?.plan_id
                              })
                              console.log("state", this.state)
                            }}
                            title={this.state?.userCountOnPlan?.find(_user => _user.plan_id === p?.plan_id)?.user_count ? "You can't delete this plan as it is used in a payment before. If you're no longer using this plan, please archive it" : "Delete"}
                            disabled={this.state?.userCountOnPlan?.find(_user => _user.plan_id === p?.plan_id)?.user_count}
                          > <AiTwotoneDelete /></button>
                        </div>
                      </div>
                      <button
                        className={p?.status ? "status" : "status-inactive"}
                        onClick={() => this.onButtonClick(p)}
                      >
                        {p?.status ? "Active" : "Inactive"}
                      </button>
                    </div>
                  );
                })}
            </Slider>
          </>
        )}
        <div>
          <div>
            <Modal open={hasOpen} center onClose={this.onCloseModal}>
              <div className="popup-box">
                <h2 className="popup-box-text pt-5 text-center">
                  {`Are You Sure Want To Change The Status to ${
                    !this.state.selectedPlan?.status ? "Active" : "Inactive"
                  }`}
                  ?
                </h2>

                <div className="modal-flex mt-5">
                  <button
                    className="modal-btn mx-5"
                    onClick={this.handleChangeStatus}
                  >
                    Confirm
                  </button>
                  <button
                    className="modal-btn mx-5 "
                    onClick={this.onCloseModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
          </div>
          <div>
          <Modal open={hasDelOpen} center onClose={this.onDelCloseModal}>
              <div className="popup-box">
                <h2 className="popup-box-text pt-5 text-center">
                 Are you sure you want to delete this plan?
                </h2>

                <div className="modal-flex mt-5">
                  <button
                    className="modal-btn mx-5"
                    onClick={this.handleDeletePlan}
                  >
                    Confirm
                  </button>
                  <button
                    className="modal-btn mx-5 "
                    onClick={this.onDelCloseModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
