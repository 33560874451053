import React, { useState, useEffect } from "react";
import "./App.css";
import "../src/style/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  redirect,
} from "react-router-dom";
import Login from "./components/pages/Login";
import Dashboard from "./components/pages/Dashboard";
import Sidebar from "./components/common/Sidebar";
import Plansetting from "./components/pages/Plansetting";
import Faqs from "./components/pages/Faqs.js";
import Subscribers from "./components/pages/Subscribers";
import NoPage from "./components/common/NoPage";

function App() {
  const [user, setUser] = useState("");

  useEffect(() => {
    setUser(localStorage.getItem("user_email"));
  }, [localStorage.getItem("user_email")]);

  useEffect(() => {
 if (localStorage.getItem("user_email") &&  window.location.pathname=="/") 
  {
    window.location.pathname="/dashboard"
  }
  }, [])

 
  
  // const Redirect = RedirectFunction();
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Login />} />
        {!localStorage.getItem("user_email") && (
          <Route path="*" exact element={<NoPage />}></Route>
        )}
      </Routes>

      <Routes>
        <Route
          path="*"
          element={
            <div>
            {window.location.pathname == "/" ? "" : <NoPage />}
            </div>
          }
        />

        <Route
          path="/dashboard"
          exact
          element={
            <div>
    
              {window.location.pathname !== "*" && (
                <Sidebar>
                  <Dashboard />
                </Sidebar>
              )}
            </div>
          }
        />

        <Route
          path="/subscribers"
          exact
          element={
            <div>
              {window.location.pathname !== "*" && (
                <Sidebar>
                  <Subscribers />
                </Sidebar>
              )}
            </div>
          }
        />
        <Route
          path="/Plan"
          exact
          element={
            <div>
              {window.location.pathname !== "*" && (
                <Sidebar>
                  {" "}
                  <Plansetting />
                </Sidebar>
              )}
            </div>
          }
        />
      </Routes>

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
      />
    </Router>
  );
}

export default App;
