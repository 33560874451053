export const createPlan = async (data) => {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/plan/createPlan",
      requestOptions
    );
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson && (await response.json());
    return data;
  } catch (err) {
    return err;
  }
};

export const getPlan = async (id) => {
  const requestOptions = {
    method: "GET",
    credentials: "include", // added this part
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + `/plan/retrievePlan/${id}`,
      requestOptions
    );
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson && (await response.json());
    return data;
  } catch (err) {
    return err;
  }
};

export const fetchPlans = async () => {
  const requestOptions = {
    method: "GET",
    credentials: "include", // added this part
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/plan/fetchAllPlans",
      requestOptions
    );
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson && (await response.json());
    return data;
  } catch (err) {
    return err;
  }
};

export const UpdatePlanStatus = async (data) => {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/plan/updatePlanStatus",
      requestOptions
    );
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson && (await response.json());
    return data;
  } catch (error) {
    return error;
  }
};

export const updatePlanData = async (data) => {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/plan/updatePlan",
      requestOptions
    );
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson && (await response.json());
    return data;
  } catch (err) {
    return err;
  }
};

export const getUserCountForPlans = async () => {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    }
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/getUserCountForPlans",
      requestOptions
    );
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson && (await response.json());
    return data;
  } catch (err) {
    return err;
  }
}


export const DeletePlans = async (id) =>{
  const requestOptions = {
    method:'GET',
    credentials:'include',
    headers:{
      "Content-Type":"application/json",
    }
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + `/plan/deletePlan/${id}`,
      requestOptions
    );
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson && (await response.json());
    return data;
  } catch (error) {
    return error;
  }
}

