export const getDashboadStats = async (id) => {
    const requestOptions = {
      method: "GET",
      credentials: "include", // added this part
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      let response = await fetch(
        process.env.REACT_APP_API_BASE_URL + `/getDashboadStats`,
        requestOptions
      );
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
      return data;
    } catch (err) {
      return err;
    }
  };