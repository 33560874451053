
export const login = async (data) => {
    const requestOptions = {
      method: "POST",
      credentials: "include", // added this part
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {
      let response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/login",
        requestOptions
      );
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
  
      return data;
    } catch (err) {
      return err;
    }
  };

  export const logout = async () => {
    const requestOptions = {
      method: "GET",
      credentials: "include", // added this part
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      let response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/logout",
        requestOptions
      );
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
  
      return data;
    } catch (err) {
      return err;
    }
  };