import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Currencies from "../../../config/currency.json";
import { createPlan } from "../../../services/PlanServices";
import Loader from "../../common/Loader";
import { TiTick } from "react-icons/ti";
import {
  UpdatePermissionList,
  fetchPermissionList,
} from "../../../services/PermissionServices";
import { selectLabel } from "../../../config/selectLabel"

const AddPlan = (props) => {
  const [currency, setCurrency] = useState({
    symbol: "$",
    code: "usd",
  });
  const [planName, setPlanName] = useState("");
  const [period, setPeriod] = useState("day");
  const [functions, setFunctions] = useState([]);
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [reloadData, setReloadData] = useState(false);
  const [permissions, setPermissions] = useState("none");

  useEffect(() => {
    const fetch = async () => {
      setPlanName(planName ? planName : "");
      setPeriod("day");
      setPrice(price ? price : 0);
      setFunctions({});
      setCurrency({
        symbol: currency.symbol ? currency.symbol : "$",
        code: currency.code ? currency.code : "usd",
      });
      setFunctions([]);
      let obj = {};
      let res = [];

      const p = await fetchPermissionList();
      setPermissions(p?.data);
      p?.data?.map((p, i) => {
        if(p.status){
          if (typeof p?._value === "object") {
            obj = {
              _key: p?._key,
              _label: p?._label,
              _value: [],
            };
          } else {
            obj = {
              _key: p?._key,
              _label: p?._label,
              _value: p?._value,
            };
          }
          res.push(obj);
        }
       
      });
      setFunctions(res);
    };

    fetch();
  }, [reloadData]);

  const handleFunctionSelect = (e, p, k) => {
    if (typeof p?._value === "boolean") {
      functions[k] = {
        _key: p?._key,
        _label: p?._label,
        _value: e.target.checked,
      };
    }
    if (typeof p?._value === "number") {
      functions[k] = {
        _key: p?._key,
        _label: p?._label,
        _value: Number(e.target.value),
      };
    }
    if (typeof p?._value === "object") {
      var options = e.target.options;
      var selectedValues = [];
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          selectedValues.push(
            p?.Pid === 11 || p?.Pid === 12
              ? Number(options[i].value)
              : options[i].value
          );
        }
      }
      functions[k] = {
        _key: p?._key,
        _label: p?._label,
        _value: selectedValues,
      };
    }
    console.log("functions====>", functions)
  };

  const handlePlanSave = async (e) => {
    e.preventDefault();
    if (planName === "" || planName.trim() === "") {
      toast.error("Please Enter Plan Name");
      return;
    }
    setLoading(true);
    try {
      let reqData = {
        name: planName,
        amount: price,
        currency: currency.code?.toLowerCase(),
        interval: period,
        functions: functions,
      };
      const _plan = await createPlan(reqData);
      if (_plan.statusCode === 200) {
        setLoading(false);
        toast.success(_plan.message);
        props.handleBtnSelect("list");
        return;
      } else {
        setLoading(false);
        if (_plan?.message?.includes("Authentication Error")) {
          toast.error("Your session has expired. Please login again.");
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
          return;
        }
        return;
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const handleEditPermission = async () => {
    try {
      const _p = await UpdatePermissionList({
        Pid: selectedId,
        _label: label,
      });
      if (_p?.statusCode === 200) {
        toast.success(_p?.message);
      } else {
        toast.error(_p?.message);
      }
      setLabel("");
      setSelectedId("");
      setReloadData(!reloadData);
      return;
    } catch (error) {
      console.log("Error in update permission", error);
    }
  };

  return (
    <>
      {loading && <Loader msg="Loading..." />}
      <div className="plan-container">
        <div className="label_text_group">
          <div className="label_text">
            <label>Plan Name</label>
            <input
              type="text"
              placeholder="Enter Plan Name"
              value={planName}
              onChange={(e) => setPlanName(e.target.value)}
              maxLength={25}
            />
          </div>
          <div className="label_text">
            <label>Subscription Period</label>
            <select
              className="subscription_select"
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
            >
              <option value="day" selected>
                Day
              </option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div>
        </div>
       
        <div className="permission-list">
          <ul>
            {permissions !== "none" &&
              permissions?.map((p, i) => {
                return (
                  p?.status && (
                    <li key={i}>
                      <div>
                        <input
                          type="text"
                          defaultValue={p?._label}
                          className="label-input"
                          onChange={(e) => {
                            setSelectedId(p?.Pid);
                            setLabel(e.target.value);
                          }}
                        ></input>
                        {label && selectedId - 1 === i && (
                          <TiTick
                            className={`edit-tick`}
                            onClick={handleEditPermission}
                          />
                        )}
                      </div>
                      {typeof p?._value === "boolean" ? (
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          onChange={(e) => handleFunctionSelect(e, p,i)}
                        />
                      ) : typeof p?._value === "number" ? (
                        <input
                          type="text"
                          defaultValue={p?._value}
                          onKeyPress={(e) => {
                            if (!/^\d*$/.test(e.key)) e.preventDefault();
                          }}
                          maxLength={10}
                          onChange={(e) => handleFunctionSelect(e, p,i)}
                        ></input>
                      ) : (
                        <select
                          className="select"
                          onChange={(e) => handleFunctionSelect(e, p,i)}
                          multiple
                        >
                          {p?._value?.map((v, key) => {
                            return (
                              <option key={key} value={v}>
                                 {typeof v === "string" ? v : selectLabel[v]}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    </li>
                  )
                );
              })}
            <li>
              <input
                type="text"
                value="Select Currency"
                className="label-input"
                disabled
                style={{
                  color: "#000",
                }}
              ></input>
              <select
                value={currency.symbol}
                onChange={(e) => {
                  setCurrency({
                    symbol: e.target.value,
                    code: e.target.selectedOptions[0].className,
                  });
                }}

              >
                {Object.keys(Currencies).map((key, i) => {
                  return (
                    <option
                      key={i}
                      value={Currencies[key]?.symbol}
                      className={Currencies[key]?.code}
                    >
                      {Currencies[key]?.name}
                    </option>
                  );
                })}
              </select>
            </li>
          </ul>
          <div className="price">
            <label>Set Price</label>
            <div className="input-group">
              <span class="input-group-text rounded-0">{currency.symbol}</span>
              <input
                type="text"
                class="form-control"
                onKeyPress={(e) => {
                  if (!/^\d*$/.test(e.key)) e.preventDefault();
                }}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                aria-label="Dollar amount (with dot and two decimal places)"
              />
            </div>
          </div>
        
        </div>
        <div className="note-div">
          <span className="note-text">Note:</span> If you want to select multiple values, please press ctrl and then select values.
        </div>
      </div>
      <div className="save-plan">
        <button className="plan-btn" onClick={(e) => handlePlanSave(e)}>
          Save Plan
        </button>
      </div>
    </>
  );
};

export default AddPlan;
