import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Currencies from "../../../config/currency.json";
import { getPlan, updatePlanData } from "../../../services/PlanServices";
import Loader from "../../common/Loader";
import { selectLabel } from "../../../config/selectLabel"

const EditPlan = (props) => {
  const [currency, setCurrency] = useState({
    symbol: "",
    code: "",
  });
  const [planName, setPlanName] = useState("");
  const [period, setPeriod] = useState("");
  const [functions, setFunctions] = useState([]);
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        let plan = await getPlan(props.id);
        if(plan.statusCode === 200){
          plan = plan.data;
          setPlanName(plan?.name);
          setPeriod(plan?.period);
          setPrice(plan?.price);
          let Currency = Currencies[plan?.currency?.toUpperCase()];
          setCurrency({
            code: Currency?.code,
            symbol: Currency.symbol,
          });
          let obj = {};
          let res = [];
          plan?.functions.map((p) => {
            obj = {
              _key: p?._key,
              _label: p?._label,
              _value: p?._value,
            };
            res.push(obj);
          });
          setFunctions(res);
        }else{
          if(plan.statusCode === 401){
            toast.error(plan.message);
            localStorage.clear();
            window.location.href = "/"
            return;
          }
        }
     
      } catch (error) {
        console.log("error", error);
      }
    };
    fetch();
  }, [props]);

  const handleFunctionSelect = (e, p, k) => {
    const newFunctions = [...functions];

    if (typeof p?._value === "boolean") {
      newFunctions[k]._value = e.target.checked;
    }
    if (typeof p?._value === "number") {
      newFunctions[k]._value = Number(e.target.value);
    }
    if (typeof p?._value === "object") {
      var options = e.target.options;
     
      var selectedValues = [];
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          selectedValues.push(
            p?.Pid === 11 || p?.Pid === 12
              ? Number(options[i].value)
              : options[i].value
          );
        }
      }
      newFunctions[k]._value = selectedValues;
    }
    setFunctions(newFunctions);
  };

  const handlePlanSave = async (e) => {
    e.preventDefault();
    if (planName === "" || planName.trim() === "") {
      toast.error("Please Enter Plan Name");
      return;
    }
    setLoading(true);
    try {
      let reqData = {
        plan_id: props.id,
        name: planName,
        amount: price,
        currency: currency.code?.toLowerCase(),
        interval: period,
        functions: functions,
      };
      const _plan = await updatePlanData(reqData);
      if (_plan.statusCode === 200) {
        setLoading(false);
        toast.success(_plan.message);
        props.handleBtnSelect("list");
        return;
      } else {
        setLoading(false);
        toast.error(_plan.message);
        return;
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {loading && <Loader msg="Loading..." />}
      <div className="plan-container">
        <div className="label_text_group">
          <div className="label_text">
            <label>Plan Name</label>
            <input
              type="text"
              placeholder="Enter Plan Name"
              value={planName}
              onChange={(e) => setPlanName(e.target.value)}
              maxLength={25}
            />
          </div>
          <div className="label_text">
            <label>Subscription Period</label>
            <select
              className="subscription_select no-csr"
              value={period}
              disabled
            >
              <option value="day" selected>
                Day
              </option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div>
        </div>
        <div className="permission-list">
          <ul>
            {props.permissions &&
              props.permissions?.map((p, i) => {
                return (
                  p?.status &&
                 ( <li key={i}>
                    <span className="edit-label">{p?._label}</span>
                    {typeof p?._value === "boolean" ? (
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        onChange={(e) => {
                          handleFunctionSelect(e, p,i);
                        }}
                        checked={functions[i]?._value}
                      />
                    ) : typeof p?._value === "number" ? (
                      <input
                        type="text"
                        defaultValue={functions[i]?._value}
                        onKeyPress={(e) => {
                          if (!/^\d*$/.test(e.key)) e.preventDefault();
                        }}
                        onChange={(e) => handleFunctionSelect(e, p, i)}
                        maxLength={10}
                      ></input>
                    ) : (
                      <select
                        className="select"
                        onChange={(e) => handleFunctionSelect(e, p, i)}
                        multiple
                      >
                       
                        {p?._value?.length > 0 && p?._value?.map((v, key) => {
                          return (
                            <option
                              key={key}
                              value={v}
                              selected={functions[i]?._value && Array.isArray(functions[i]?._value) && functions[i]?._value.includes(v)}
                            >
                             {typeof v === "string" ? v : selectLabel[v]}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </li>)
                );
              })}
            <li>
              <span className="edit-label">Select Currency</span>
              <select value={currency.symbol} className="no-csr" disabled>
                {Object.keys(Currencies)?.map((key, i) => {
                  return (
                    <option
                      key={i}
                      value={Currencies[key]?.symbol}
                      className={Currencies[key]?.code}
                    >
                      {Currencies[key]?.name}
                    </option>
                  );
                })}
              </select>
            </li>
          </ul>
          <div className="price">
            <label style={{paddingLeft:"27px"}}>Set Price</label>
            <div className="input-group">
              <span class="input-group-text rounded-0">{currency.symbol}</span>
              <input
                type="text"
                className="form-control no-csr"
                onKeyPress={(e) => {
                  if (!/^\d*$/.test(e.key)) e.preventDefault();
                }}
                value={price}
                disabled
                aria-label="Dollar amount (with dot and two decimal places)"
              />
            </div>
          </div>
        </div>
        <div className="note-div">
          <span className="note-text">Note:</span> If you want to select multiple values, please press ctrl and then select values.
        </div>
      </div>
      <div className="save-plan">
        <button className="plan-btn" onClick={(e) => handlePlanSave(e)}>
          Save Plan
        </button>
      </div>
    </>
  );
};

export default EditPlan;