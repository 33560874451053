import React from "react";
import { Oval } from "react-loader-spinner";

function Loader(props) {
  return (
    <div className="loader-container">
      <div className="loader-body">
        <Oval
          height={50}
          width={50}
          color="#9952ff"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#9952ffa8"
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
        <div className="loader-content mt-3">{props.msg}</div>
      </div>
    </div>
  );
}

export default Loader;
