import React, { useState } from "react";
import logo from "../../assets/images/Logo.png";
import {
  FaBars,
  FaFoursquare,
  FaRegChartBar,
  FaTh,
  FaUserAlt,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

export default function Sidebar({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = () => {
    setIsOpen(false);
  };

  const MenuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <FaTh />,
    },

    {
      path: "/subscribers",
      name: "Subscribers",
      icon: <FaUserAlt />,
    },

    {
      path: "/plan",
      name: "Subscription Plans",
      icon: <FaRegChartBar />,
    },

    // {
    //   path: "/Faqs",
    //   name: "Faqs",
    //   icon: <FaFoursquare />,
    // },
  ];

  return (
    <div className="main-container ">
      <div
        style={{ width: isOpen ? "350px" : "78px" }}
        className="sidebar pt-5"
      >
        <div className="top_section px-2 mb-3">
          <div className="logo-container ">
            <img
              src={logo}
              alt="/"
              className="logo mx-1"
              style={{ display: isOpen ? "block" : "none" }}
            />
           
            <div
              className="bars"
              style={{ marginLeft: isOpen ? "120px" : "0px" }}
            >
              <FaBars onClick={toggle} />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div>
            <h5
              className="py-2 essential"
              style={{ display: isOpen ? "block" : "none" }}
            >
              Essential
            </h5>
          </div>
          {MenuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className="link"
              active="true"
              onClick={handleClick}
            
            >
              <div className="icon">{item.icon}</div>
              <div
                className="link_text"
                style={{ display: isOpen ? "block" : "none" }}
              >
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>


        <div
          className="copyright"
          style={{ display: isOpen ? "block" : "none" }}
        >
          &copy; 2023 - MintSuite by BlockchainAustralia
        </div>
      </div>
      <main className="section-container">{children}</main>
    </div>
  );
}
