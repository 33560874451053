import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Icon from "../../assets/images/bbell.png";
import {
  getSubscribersList,
  getSubscription,
  pauseSubscription,
  resumeSubscription,
} from "../../services/SubscriberServices";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import { pageLimit } from "../../config/constants";
import { Modal } from "react-responsive-modal";
import { fetchPlans } from "../../services/PlanServices";
import ContentLoader from "../common/ContentLoader";
import DatePicker from "react-date-picker";
import moment from "moment";
import Loader from "../common/Loader";
import { toast } from "react-hot-toast";
import { AiFillPauseCircle } from "react-icons/ai";

function Subscribers() {
  const [subscribers, setSubscribers] = useState("none");
  const [currentPage, setCurrentPage] = useState(1);
  const [counts, setCounts] = useState(0);
  const [searchObj, setSearchObj] = useState({
    key: "",
    value: "",
  });
  const [searchClicked, setSearchClicked] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState("");
  const [manageState, setManageState] = useState({
    subscription_id: "",
    behavior: "",
    resumes_at: null,
    email: "",
  });
  const [currentPeriodEndDate, setCurrentPeriodEndDate] = useState("");
  const [loader, setLoader] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const resetManageState = () => {
    setLoader(false);
    setToggle(!toggle);
    setManageState({
      subscription_id: "",
      behavior: "",
      resumes_at: null,
      email: "",
    });
  };



  useEffect(() => {
    const fetchList = async () => {
      try {
        const list = await fetchPlans();
        setPlanList(list.data);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchList();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        let searchArray = [];
        if (searchObj.key !== "" && searchClicked) {
          searchArray[searchObj.key] = searchObj.value;
        }
        searchArray["page"] = currentPage;
        searchArray["limit"] = pageLimit;
        let obj1 = Object.assign({}, searchArray);
        const res = await getSubscribersList(obj1);
        if (res.statusCode === 200) {
          setLoading(false);
          setSubscribers(res.data?.results[0]);
          setCounts(res.data?.count);
        }
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    fetch();
  }, [currentPage, toggle]);

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (searchObj.value === "") setSearchClicked(false);
  };

  const handleSearch = () => {
    setSearchClicked(true);
    setCurrentPage(1);
    setToggle(!toggle);
    setAction("");
  };

  const [OpenModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
    setAction("");
    setManageState({
      resumes_at: null,
    });
  };

  const PauseModal = () => {
    return (
      <div className="manage-popup-box pt-2 px-2 text-left">
        <div className="manage-border pb-3">
          <h5 className="popup-box-text ">Pause Payment Collection</h5>
          <span>
            Decide how long to pause payment collection and set invoice
            behavior.
          </span>
        </div>

        <div className="outer-pause-container pt-4">
          <div>
            <h6 className="font-bold" style={{ minWidth: "140px" }}>
              Pause duration
            </h6>
          </div>
          <div>
            <div className="padding-box">
              <input
                type="radio"
                name="pause_duration"
                id="indefinite_"
                value=""
                onClick={(e) => {
                  setManageState({
                    ...manageState,
                    resumes_at: e.target.value,
                  });
                }}
              />{" "}
              <label htmlFor="indefinite_" className="font-bold px-1">
                Indefinite
              </label>
            </div>
            <div className="custom-date">
              <div className="padding-box pt-1">
                <input
                  type="radio"
                  name="pause_duration"
                  id="Untill_"
                  value="resumes_at"
                  onClick={(date) => {
                    setManageState({
                      ...manageState,
                      resumes_at: moment(currentPeriodEndDate).unix(),
                    });
                  }}
                />{" "}
                <label htmlFor="Untill_">
                  <span className="font-bold px-1">Until a common date</span>
                </label>
              </div>
              <div>
    
                {manageState.resumes_at && (
                  <DatePicker
                    onChange={(date) => {
                      console.log("date===>", moment(date).unix());
                      setCurrentPeriodEndDate(date);
                      setManageState({
                        ...manageState,
                        resumes_at: moment(date).unix(),
                      });
                    }}
                    value={currentPeriodEndDate}
                    minDate={new Date()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="outer-pause-container pt-4">
          <div>
            <h6 className="font-bold" style={{ minWidth: "140px" }}>
              Invoice Behavior
            </h6>
          </div>
          <div>
            <div className="padding-box">
              <input
                type="radio"
                name="pause_duration2"
                id="keepDraft"
                value="keep_as_draft"
                onClick={(e) => {
                  setManageState({
                    ...manageState,
                    behavior: e.target.value,
                  });
                }}
              />{" "}
              <label htmlFor="keepDraft" className="font-bold px-1">
                Keep invoices as drafts{" "}
              </label>
              <p className="px-d">
                For businesses currently offering services but waiting to
                collect payments.
              </p>
            </div>
            <div className="custom-date">
              <div className="padding-box pt-1">
                <input
                  type="radio"
                  name="pause_duration2"
                  id="invoice"
                  value="mark_uncollectible"
                  onClick={(e) => {
                    setManageState({
                      ...manageState,
                      behavior: e.target.value,
                    });
                  }}
                />{" "}
                <label htmlFor="invoice">
                  <span className="font-bold px-1">
                    Mark invoices uncollectible
                  </span>
                </label>
                <p className="px-d">
                  For businesses currently offering services for free.
                </p>
              </div>
            </div>

            <div className="custom-date">
              <div className="padding-box pt-1">
                <input
                  type="radio"
                  name="pause_duration2"
                  id="void_invoice"
                  value="void"
                  onClick={(e) => {
                    setManageState({
                      ...manageState,
                      behavior: e.target.value,
                    });
                  }}
                />{" "}
                <label htmlFor="void_invoice">
                  <span className="font-bold px-1">Void invoices</span>
                </label>
                <p className="px-d">
                  For businesses currently offering services.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="manage-flex mt-4 mb-2">
          <button className="manage-box-btn" onClick={handlePauseSubscription}>
            Pause
          </button>
          <button
            className="manage-box-btn mx-2 "
            onClick={() => {
              handleCloseModal();
            }}
          >
            Go back
          </button>
        </div>
      </div>
    );
  };

  const resumeModal = () => {
    return (
      <div>
        <div>
          <h5>Resume payment collection</h5>
          Are you sure you want to resume collecting payments? Any future
          invoices for this subscription will resume payment collection.
        </div>

        <div className="manage-flex mt-4 mb-2">
          <button className="manage-box-btn" onClick={handleResumeSubscription}>
            Resume
          </button>
          <button
            className="manage-box-btn mx-2 "
            onClick={() => {
              handleCloseModal();
            }}
          >
            Go back
          </button>
        </div>
      </div>
    );
  };

  const selectModal = () => {
    return (
      <>
        <div className="mr-5">
          <h2 className="manage-header">Manage Payment Collection</h2>
          What action would you like to take regarding the payment collection
          aspect of this subscription?
        </div>

        <div className=" mt-4 px-2 mb-3">
          <div className="d-flex justify-content-center mt-3">
            <button
              className="manage-box-btn mx-2"
              onClick={() => {
                setAction("pause");
              }}
            >
              Pause
            </button>
            <button
              className="manage-box-btn mx-2 "
              onClick={() => {
                setAction("resume");
              }}
            >
              Resume
            </button>
          </div>
        </div>
      </>
    );
  };

  //function to get subscription data
  const fetchSubscriptionDetails = async (_id, _email) => {
    console.log("email and id", _id, _email);
    try {
      const _subscription = await getSubscription(_id);
      const _ct = moment
        .unix(_subscription?.data?.current_period_end)
        .local()
        .format();
      setCurrentPeriodEndDate(_ct);
      setIsPaused(_subscription?.data?.pause_collection);
      setManageState({
        ...manageState,
        subscription_id: _id,
        email: _email,
      });
    } catch (error) {
      console.log("errrrr", error);
    }
  };

  const handlePauseSubscription = async () => {
    try {
      if (manageState?.resumes_at === null) {
        toast.error("Please select the pause duration");
        return;
      }

      if (!manageState?.behavior || manageState?.behavior === "" ) {
        toast.error("Please select the invoice behavior");
        return;
      }
      setLoader(true);
      setOpenModal(false);
      const _res = await pauseSubscription(manageState);
      if (_res?.statusCode === 200) {
        toast.success(_res?.message);
        resetManageState();
        return;
      } else {
        toast.error(_res.message);
        resetManageState();
        return;
      }
    } catch (error) {
      resetManageState();
      console.log("error", error);
    }
  };

  const handleResumeSubscription = async () => {
    try {
      if (isPaused === null) {
        toast.error(
          "You cannot resume a payment collection that's not paused."
        );
        return;
      }
      setLoader(true);
      setOpenModal(false);
      const _res = await resumeSubscription({
        subscription_id: manageState.subscription_id,
        email: manageState?.email,
      });
      if (_res?.statusCode === 200) {
        toast.success(_res?.message);
        resetManageState();
        return;
      } else {
        toast.error(_res.message);
        resetManageState();
        return;
      }
    } catch (error) {
      resetManageState();
      console.log("error", error);
    }
  };
  return (
    <section className="section-head">
      {loader && <Loader msg="Please wait while we process your request..." />}
      <Header />
      <div className="content-body">
        <div className="main-section">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="heading">Subscribers</h1>
            <div className="imgContainer">
              <img src={Icon} alt="bell" />
            </div>
          </div>
          <div className=" pb-3 filter my-3 d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <select
                className="custom-select"
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    key: e.target.value,
                    value: "",
                  });
                  setSearchClicked(false);
                }}
              >
                <option value="" selected>
                  Select Option
                </option>
                <option value="wallet">Wallet Address</option>
                <option value="email">Email</option>
                <option value="subscription_id">Subscription ID</option>
                <option value="plan_id">Subscribed Plan</option>
                <option value="subs_status">Subscription Status</option>
              </select>
              {searchObj?.key &&
                searchObj?.key !== "plan_id" &&
                searchObj?.key !== "subs_status" && (
                  <input
                    type="text"
                    className="ml-3 search-input"
                    placeholder="Enter search value"
                    value={searchObj?.value}
                    onChange={(e) => {
                      setSearchObj({
                        ...searchObj,
                        value: e.target.value,
                      });
                      setSearchClicked(false);
                    }}
                  ></input>
                )}
              {searchObj?.key &&
                searchObj?.key === "plan_id" &&
                searchObj?.key !== "subs_status" && (
                  <select
                    className="custom-select ml-3"
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setSearchObj({
                          ...searchObj,
                          key: "plan_id",
                          value: "",
                        });
                      } else
                        setSearchObj({
                          ...searchObj,
                          key: "plan_id",
                          value: e.target.value,
                        });
                      setSearchClicked(false);
                    }}
                  >
                    <option value="" selected>
                      Select Option
                    </option>
                    {planList?.length > 0 &&
                      planList?.map((_p, key) => {
                        return (
                          <option value={_p?.plan_id} key={key}>
                            {_p?.name}
                          </option>
                        );
                      })}
                  </select>
                )}

              {searchObj?.key &&
                searchObj?.key !== "plan_id" &&
                searchObj?.key === "subs_status" && (
                  <select
                    className="custom-select ml-3"
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setSearchObj({
                          ...searchObj,
                          key: "subs_status",
                          value: "",
                        });
                      } else
                        setSearchObj({
                          ...searchObj,
                          key: "subs_status",
                          value: e.target.value === "true",
                        });
                      setSearchClicked(false);
                    }}
                  >
                    <option value="" selected>
                      Select Option
                    </option>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                )}

              <button
                type="button"
                className="btn  btn-sm btn-rounded filter-btn"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>

          <div className="table-content  table-responsive">
            <table className="table align-middle mb-0 bg-white">
              <thead className="bg-light table-fixed">
                <tr>
                  <th>Subscription ID</th>
                  <th>Wallet Address</th>
                  <th>Email</th>
                  {/* <th>Subscribed Plan</th> */}
                  <th>Plan ID</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div className="content-wait-loader no-text-2">
                    <ContentLoader />
                  </div>
                ) : !loading &&
                  subscribers !== "none" &&
                  subscribers?.length > 0 ? (
                  subscribers?.map((sub, key) => {
                    return (
                      <tr key={key}>
                        <td>{sub?.plan?.subscription_id}</td>
                        <td>{sub?.wallet}</td>
                        <td>{sub?.email}</td>
                        {/* <td>{sub?.plan?._name}</td> */}
                        <td>{sub?.plan?.id} <span className="plan-name-span">({sub?.plan?.name})</span></td>
                        <td className="d-flex align-items-center">
                          <span
                            className={`badge rounded-pill d-inline ${
                              sub?.plan?.status
                                ? "badge-success"
                                : "badge-danger"
                            }`}
                          >
                            {sub?.plan?.status ? "Active" : "Inactive"}
                          </span>
                          {sub?.pause_collection && (
                            <AiFillPauseCircle
                              className="ml-1 info-icon"
                              title={`The subscription is paused till ${
                                sub?.pause_collection?.resumes_at
                                  ? moment
                                      .unix(sub?.pause_collection?.resumes_at)
                                      .local()
                                      .format("LL")
                                  : "indefinite time"
                              } having ${
                                sub?.pause_collection?.behavior
                              } as invoice behavior`}
                            />
                          )}
                        </td>

                        <td>
                          <button
                            type="button"
                            className="btn  btn-sm btn-rounded manage-btn"
                            disabled={sub?.plan?.status ? false : true}
                            onClick={() => {
                              fetchSubscriptionDetails(
                                sub?.plan?.subscription_id,
                                sub?.email
                              );
                              setOpenModal(true);
                              setAction("");
                            }}
                          >
                            Manage
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div className="no-text p-2">
                    <p>No Results Found</p>
                  </div>
                )}
              </tbody>
            </table>
          </div>
          {Math.ceil(counts / pageLimit) > 1 && (
            <Pagination
              {...bootstrap5PaginationPreset}
              current={currentPage}
              total={Math.ceil(counts / pageLimit)}
              onPageChange={nextPage}
            />
          )}
        </div>
      </div>

      <Modal open={OpenModal} center onClose={handleCloseModal}>
        {action === ""
          ? selectModal()
          : action === "pause"
          ? PauseModal()
          : resumeModal()}
      </Modal>
    </section>
  );
}

export default Subscribers;
