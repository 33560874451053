  export const fetchPermissionList = async () => {
    const requestOptions = {
      method: "GET",
      credentials: "include", 
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      let response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/permission/getPermissionList",
        requestOptions
      );
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
      return data;
    } catch (err) {
      return err;
    }
  };


  export const UpdatePermissionList = async (data) =>{
    const requestOptions ={
      method: "POST",
      credentials: "include", 
      headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/permission/updatePermission",
      requestOptions
    );
    const isJson = response.headers
    .get("content-type")
    ?.includes("application/json");
  const data = isJson && (await response.json());
  return data;
  } catch (error) {
    return error;
  }
  };