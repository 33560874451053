import React, { useState } from "react";
import { toast } from "react-hot-toast";
import Logo from "../../assets/images/Logo.jpg";
import { login } from "../../services/AuthServices";

export default function Login() {
  const [userData, setUserData] = useState({
    username: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData.username === "" || userData.username?.trim() === "") {
      toast.error("Please Enter your Username/Email");
      return;
    }
    if (userData.password === "" || userData.password?.trim() === "") {
      toast.error("Please Enter your Password");
      return;
    }
    try {
      let reqBody = {
        username: userData.username,
        password: userData.password,
      };
      const res = await login(reqBody);
      if (res.statusCode === 200) {
        console.log("res", res);
        localStorage.setItem("user_email", res.data?.userData?.email);
        toast.success("User Logged in Successfully");
        setTimeout(() => {
          window.location.href = "/dashboard";
        },1000);
       
      } else {
        setUserData({
          username: "",
          password: "",
        });
        toast.error(res.message);
      }
    } catch (error) {
      setUserData({
        username: "",
        password: "",
      });
      console.log("error in login api", error);
    }

  
  };

  const handleInputChange = (e) => {
    setUserData({
      ...userData,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <>
      <div className="h-full flex bg">
        <div className="container">
          <div className="row flex">
            <div className="col-md-6 ">
              <form>
                <div className="main border card-bg mx-auto card-box">
                  <div className="p-4">
                    <img src={Logo} className="logo" alt="/" />
                  </div>
                  <h5 className="px-5 text-dark">Hello! let's get started</h5>
                  <h6 className="px-5 pt-1 text-dark">Sign in to continue.</h6>
                  <div className="Username p-1">
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      placeholder="Username/Email"
                      value={userData.username}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div className="Username p-1">
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Password"
                      value={userData.password}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div className="login-btn-box flex justify-content-center">
                    <button
                      type="submit"
                      className="btn"
                      onClick={(e) => handleSubmit(e)}
                    >
                      SIGN IN
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
