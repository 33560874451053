
import React from "react";
import Header from "../common/Header";
import Icon from "../../assets/images/bbell.png";

function Faqs() {
  return (
    <section className="section-head">
    <Header />
    <div className="content-body">
    <div className="main-section h-full">
    <div className="d-flex justify-content-between align-items-center">
        <h1 className="heading">FAQs</h1>
        <div className="imgContainer">
          <img src={Icon} alt="bell" />
        </div>
      </div>
    </div>

    <div className="container">
     
    </div>


    {/* bottom container */}

    <div className="container">
    
    </div>
    </div>
  </section>
  );
}

export default Faqs;

