import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { logout } from "../../services/AuthServices";
import logo from "./../../assets/images/tlogo.png";

function Header() {
  const [user, setUser] = useState("");
  useEffect(() => {
    setUser(localStorage.getItem("user_email"));
  }, [localStorage.getItem("user_email")]);

  const Logout = async () => {
    try {
      const res = await logout();
      if (res.statusCode === 200) {
        localStorage.clear();
        toast.success("User Logged Out Successfully");
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
        return;
      } else {
        if (res?.message?.includes("Authentication Error")) {
          toast.error("Your session has expired. Please login again.");
          localStorage.clear()
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
          return;
        }
        toast.error(res.message);
        return;
      }
    } catch (error) {
      console.log("error in logout api", error);
    }
  };

  return (
    <div className="header py-3 px-4">
      <div className="d-flex justify-content-between align-items-center">
        <span className="p-3 username">{user}</span>
        <button className="btn" onClick={Logout}>
          Logout
        </button>
      </div>
    </div>
  );
}

export default Header;
