import React from "react";
import { Link } from "react-router-dom";

function NoPage() {
  const handleBackToHome = () => {
    let _user = localStorage.getItem("user_email");
    if (_user) {
      window.location.href = "/dashboard";
    } else {
      window.location.href = "/";
    }
  };

  return (
    <div className="h-full box flex">
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div className="nopage-text-404">404</div>
          </div>
          <div className="col-xl-6">
            <div>
              <p className="nopage-text pt-4">Sorry!</p>
            </div>
            <div className="nopage-text-right">
              The page you’re looking for was not found.
            </div>
          </div>

          <div className="col-xl-12 pt-4 text-center">
            <button
              className="btn"
              style={{
                color: "white",
                "maxWidth": "max-content",
                backgroundColor: "transparent",
              }}
              onClick={handleBackToHome}
            >
              <div>
                <p className="text-center" style={{ fontWeight: "bold" }}>
                  Back to Home
                </p>
              </div>
            </button>
            <div>
              <p className="text-center">
                {" "}
                @copyright 2023 Decrypt-NFT by BlockchainAustralia
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoPage;
