export const getSubscribersList = async (data) => {
    const requestOptions = {
      method: "POST",
      credentials: "include", 
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    };
    try {
      let response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/getSubscribers",
        requestOptions
      );
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
      return data;
    } catch (err) {
      return err;
    }
};

export const getSubscription = async (id) => {
    const requestOptions = {
      method: "GET",
      credentials: "include", // added this part
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      let response = await fetch(
        process.env.REACT_APP_API_BASE_URL + `/getSubscription/${id}`,
        requestOptions
      );
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
      return data;
    } catch (err) {
      return err;
    }
};


export const pauseSubscription = async (data) => {
  const requestOptions = {
    method: "POST",
    credentials: "include", 
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/pauseSubscrption",
      requestOptions
    );
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson && (await response.json());
    return data;
  } catch (err) {
    return err;
  }
}

export const resumeSubscription = async (data) => {
  const requestOptions = {
    method: "POST",
    credentials: "include", 
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/resumeSubscription",
      requestOptions
    );
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson && (await response.json());
    return data;
  } catch (err) {
    return err;
  }
}